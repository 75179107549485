.scrollbar {
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
